import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import React, { useState, useEffect } from 'react';
// import { useSpring, animated } from '@react-spring/web';
function NavBar(){
    const [menuToggle,setToggle] = useState(false);
    // const [isScrolled, setIsScrolled] = useState(false);
    // const [style,setStyle] = useState({});

    // const navStyle = useSpring({
    //     transform: isScrolled ? 'translateY(0)' : 'translateY(-100%)',
    //     config: { tension: 300, friction: 10 }
    // });


    function handleClick(){
        if(menuToggle){
            setToggle(false);
        }else{
            setToggle(true);
        }
    }

    // useEffect(() => {
    //    // setIsScrolled(true);
    //     const handleScroll = () => {
    //       if (window.scrollY > 50) { // Adjust scroll threshold as needed
    //         setIsScrolled(true);
    //         setStyle({position:"fixed",top:"0",left:"0",right:"0",zIndex:"1000"})
    //       } else {
    //         setIsScrolled(true);
    //         setStyle({})
    //       }
    //     };
    
    //     // Attach the event listener
    //     window.addEventListener('scroll', handleScroll);
    
    //     // Clean up the event listener
    //     return () => {
    //       window.removeEventListener('scroll', handleScroll);
    //     };
    //   }, []);

    return (
    //     <animated.div
    //   style={{
    //     ...navStyle,
    //     position: 'fixed',
    //     top: 0,
    //     left: 0,
    //     right: 0,
    //     //backgroundColor:"currentcolor" ,
    //     padding: '0px 20px',
    //     zIndex: 1000,
    //     backdropFilter: 'blur(4px)',
       
    //   }}
    // >
     
        <div className="bg-gradient-to-r from-blue-900 to-blue-500 flex text-white justify-between items-center py-5 px-10 lg:px-60 md:px-40 sm:px-20 fixed top-0 right-0 left-0 z-50">
            <div className="brand text-2xl font-bold tracking-widest">BillingZone</div>
            <div className="nav-links font-medium hidden lg:block">
                <ul className="flex justify-between items-center gap-10">
                    <li>
                        <a href="#home">Home</a></li>
                    <li>
                        <a href="#chooseus">Why Us?</a></li>
                    <li>
                        <a href="#features">Features</a></li>
                    <li>
                        <a href="/">Our Brands</a></li>
                    <li>
                        <a href="#contactus">Contact</a></li>
                    <li>
                        <button className="bg-blue-100 text-blue-900 shadow-md px-10 py-4 rounded-full">
                            Try Demo
                       </button>
                    </li>
                </ul>
            </div>
            <div className="nav-links font-medium block lg:hidden">
                <div>
                    <span onClick={() => handleClick()}><MenuIcon/></span>
                    {menuToggle && 
                        <div className='fixed top-0 right-0 bg-white text-blue-700 h-screen p-10 z-50'>
                            <span onClick={() => handleClick()}><CloseIcon/></span>
                            <ul className="flex justify-between items-center flex-col gap-20">
                                <li>
                                    <a href="#home">Home</a></li>
                                <li>
                                    <a href="#chooseus">Why Us?</a></li>
                                <li>
                                    <a href="#features">Features</a></li>
                                <li>
                                    <a href="/">Our Brands</a></li>
                                <li>
                                    <a href="#contactus">Contact</a></li>
                                <li>
                                    <button className="bg-gradient-to-r from-blue-900 to-blue-500 text-white shadow-md px-10 py-4 rounded-full">
                                        Try Demo
                                </button>
                                </li>
                            </ul>
                        </div>
                    }
                </div>
            </div>
        </div>
        // </animated.div>
    );
}

export default NavBar;