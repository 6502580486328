import RocketImg from "../assets/rocket.jpg";
import UserInterface from "../assets/userInterface.jpg";
import CustomerSupport from "../assets/customerSupport.jpg";
import SecurityImg from "../assets/security.png";
import { useSpring ,animated } from '@react-spring/web';
import { useEffect, useState } from "react";
export default function ChooseUs(){
    const [isFocused, setIsFocused] = useState(false);
    
    
    const springs = useSpring({
        from:  { y: 200 },
        to:  {y: isFocused ? 0 : 200 },
        config: {tension: 300, friction: 10, duration: 1000 },
        //transform: isFocused ? 'scale(1.1)' : 'scale(1)',
      })

      useEffect(() => {
        const handleScroll = () => {
          if (window.scrollY > 50) { // Adjust scroll threshold as needed
            setIsFocused(true);
          } else {
            setIsFocused(false);
          }
        };
      
        // Attach the event listener
        window.addEventListener('scroll', handleScroll);
      
        // Clean up the event listener
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);


    return(
        <div className="bg-white block px-10 lg:px-60 md:px-40 sm:px-20 pt-10 pb-20" id="chooseus">
            <div className="text-center flex flex-col justify-center items-center mt-20">
                <h1 className="text-4xl font-semibold">Why Business Owners Love <br/> BillingZone</h1>
                <animated.div tabIndex="0" style={{ ...springs,
        transform: springs.y.to(y => `translateY(${y}px)`), }} >
                <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-20 mt-20 items-baseline">
                    <div className="flex justify-center items-center flex-col gap-10 w-80">
                        <div className="">
                            <img src={RocketImg} alt="Rocket" width={"200px"} height={"200px"} className="rounded-full"></img>
                        </div>
                        <div className="font-medium text-1.5xl">Efficiency and Accuracy.</div>
                    </div>
                    <div className="flex justify-center items-center flex-col gap-10 w-80">
                        <div className="">
                            <img src={CustomerSupport} alt="Rocket" width={"200px"} height={"200px"} className="rounded-full"></img>
                        </div>
                        <div className="font-medium text-1xl">Quick support and response.</div>
                    </div>
                    <div className="flex justify-center items-center flex-col gap-10 w-80">
                        <div className="rounded-full">
                            <img src={UserInterface} alt="Rocket" width={"200px"} height={"200px"} className="rounded-full"></img>
                        </div>
                        <div className="font-medium text-1xl">User-Friendly Interface.</div>
                    </div>
                    <div className="flex justify-center items-center flex-col gap-10 w-80">
                        <div className="rounded-full">
                            <img src={SecurityImg} alt="Rocket" width={"200px"} height={"200px"} className="rounded-full"></img>
                        </div>
                        <div className="font-medium text-1xl">Safe and secure transactions.</div>
                    </div>
                </div>
                </animated.div>
            </div>
        </div>
    );
}