// Example images
import MacBook from '../assets/macbook.png';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

import React from 'react';
import { Pagination,Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import "../css/Slider.css";


const SliderComponent = () => {
  return (
    <div className="slider-container">
        <Swiper
            // install Swiper modules
            modules={[Pagination,Autoplay]}
            spaceBetween={50}
            slidesPerView={1}
            pagination={{ clickable: true }}
            autoplay={{ delay: 3000 }}
            speed={1000}
            loop = {true}
        >
        <SwiperSlide className='mt-20 mb-20'>
          <div className="grid lg:grid-cols-2 grid-cols-1">
            <div>
              <img src={MacBook} alt="Rocket" className="md:max-w-screen" />
            </div>
            <div>
              <div className="font-medium text-2xl">Multi-Branch Management</div>
              <div className='m-2'>
                <table>
                  <tr>
                    <td className='text-left text-md p-3 text-blue-800'><span className='mr-2'><TaskAltIcon/></span></td>
                    <td className='text-left text-md p-3'><span>Our application offers a robust Multi-Branch Management module that allows you to seamlessly oversee <b className='font-semibold'>all your branches from a centralized dashboard</b>.</span></td>
                  </tr>
                  <tr>
                    <td className='text-left text-md p-3 text-blue-800'><span className='mr-2'><TaskAltIcon/></span></td>
                    <td className='text-left text-md p-3'><span>The system supports customized access levels, so <b className='font-semibold'>branch managers and staff only see the information relevant to their roles.</b></span></td>
                  </tr>
                  <tr>
                    <td className='text-left text-md p-3 text-blue-800'><span className='mr-2'><TaskAltIcon/></span></td>
                    <td className='text-left text-md p-3'><span>You can monitor sales, inventory levels, and staff performance across different locations in real-time.</span></td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className='mt-20 mb-20'>
          <div className="grid lg:grid-cols-2 grid-cols-1">
            <div>
              <img src={MacBook} alt="Rocket" className="md:max-w-screen" />
            </div>
            <div>
              <div className="font-medium text-2xl">Simplified Sales & Quotation</div>
              <div className='m-2'>
                <table>
                  <tr>
                    <td className='text-left text-md p-3 text-blue-800'><span className='mr-2'><TaskAltIcon/></span></td>
                    <td className='text-left text-md p-3'><span>The intuitive interface allows sales staff to <b className='font-semibold'>quickly process transactions</b>, manage customer profiles, and <b className='font-semibold'>track sales history.</b> </span></td>
                  </tr>
                  <tr>
                    <td className='text-left text-md p-3 text-blue-800'><span className='mr-2'><TaskAltIcon/></span></td>
                    <td className='text-left text-md p-3'><span>This leads to a faster checkout process and <b className='font-semibold'>improved customer satisfaction.</b></span></td>
                  </tr>
                  <tr>
                    <td className='text-left text-md p-3 text-blue-800'><span className='mr-2'><TaskAltIcon/></span></td>
                    <td className='text-left text-md p-3'><span>You can generate detailed sales reports, identify trends, and gain insights into customer preferences.</span></td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className='mt-20 mb-20'>
          <div className="grid lg:grid-cols-2 grid-cols-1">
            <div>
              <img src={MacBook} alt="Rocket" className="md:max-w-screen" />
            </div>
            <div>
              <div className="font-medium text-2xl">Streamlined Purchases</div>
              <div className='m-2'>
                <table>
                  <tr>
                    <td className='text-left text-md p-3 text-blue-800'><span className='mr-2'><TaskAltIcon/></span></td>
                    <td className='text-left text-md p-3'><span>The module simplifies the process and ensuring that <b className='font-semibold'>your store never runs out of stock. </b></span></td>
                  </tr>
                  <tr>
                    <td className='text-left text-md p-3 text-blue-800'><span className='mr-2'><TaskAltIcon/></span></td>
                    <td className='text-left text-md p-3'><span>Helps you manage supplier relationships, <b className='font-semibold'>track purchase orders</b>, and <b className='font-semibold'>monitor inventory levels.</b></span></td>
                  </tr>
                  <tr>
                    <td className='text-left text-md p-3 text-blue-800'><span className='mr-2'><TaskAltIcon/></span></td>
                    <td className='text-left text-md p-3'><span>You can <b className='font-semibold'>manage multiple suppliers</b>, compare prices, and choose the best options to <b className='font-semibold'>maximize your profit margins.</b></span></td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </SwiperSlide>
        {/* <SwiperSlide className='mt-20 mb-20'>
          <div className="grid lg:grid-cols-2 grid-cols-1">
            <div>
              <img src={MacBook} alt="Rocket" className="md:max-w-screen" />
            </div>
            <div className="font-medium text-2xl">Dealer Directory</div>
          </div>
        </SwiperSlide> */}
        <SwiperSlide className='mt-20 mb-20'>
          <div className="grid lg:grid-cols-2 grid-cols-1">
            <div>
              <img src={MacBook} alt="Rocket" className="md:max-w-screen" />
            </div>
            <div>
              <div className="font-medium text-2xl">Efficient Tagging System</div>
              <div className='m-2'>
                <table>
                  <tr>
                    <td className='text-left text-md p-3 text-blue-800'><span className='mr-2'><TaskAltIcon/></span></td>
                    <td className='text-left text-md p-3'><span>Our Efficient Tagging System enhances inventory management by allowing you to <b className='font-semibold'>tag and categorize products with ease.</b> </span></td>
                  </tr>
                  <tr>
                    <td className='text-left text-md p-3 text-blue-800'><span className='mr-2'><TaskAltIcon/></span></td>
                    <td className='text-left text-md p-3'><span>The tagging system also <b className='font-semibold'>integrates with sales and inventory modules</b>, ensuring that all product information is updated in real-time. </span></td>
                  </tr>
                  <tr>
                    <td className='text-left text-md p-3 text-blue-800'><span className='mr-2'><TaskAltIcon/></span></td>
                    <td className='text-left text-md p-3'><span>This system supports various types of tags, <b className='font-semibold'>including QR and barcode tags</b>, making it simple to track items throughout the store.</span></td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className='mt-20 mb-20'>
          <div className="grid lg:grid-cols-2 grid-cols-1">
            <div>
              <img src={MacBook} alt="Rocket" className="md:max-w-screen" />
            </div>
            <div>
              <div className="font-medium text-2xl">Customer Management</div>
              <div className='m-2'>
                <table>
                  <tr>
                    <td className='text-left text-md p-3 text-blue-800'><span className='mr-2'><TaskAltIcon/></span></td>
                    <td className='text-left text-md p-3'><span>This module allows you to <b className='font-semibold'>create detailed customer profiles</b>, including contact information, <b className='font-semibold'>purchase history</b>, preferences, and special occasions such as <b className='font-semibold'>birthdays and anniversaries.</b></span></td>
                  </tr>
                  <tr>
                    <td className='text-left text-md p-3 text-blue-800'><span className='mr-2'><TaskAltIcon/></span></td>
                    <td className='text-left text-md p-3'><span>You can reward repeat customers, gather valuable feedback, and implement improvements based on customer insights.</span></td>
                  </tr>
                  <tr>
                    <td className='text-left text-md p-3 text-blue-800'><span className='mr-2'><TaskAltIcon/></span></td>
                    <td className='text-left text-md p-3'><span>The integrated communication tools enable you to <b className='font-semibold'>reach out to customers via email, SMS, or social media</b>, ensuring that you stay connected and engaged with your customer base.</span></td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </SwiperSlide>
        {/* <SwiperSlide className='mt-20 mb-20'>
          <div className="grid lg:grid-cols-2 grid-cols-1">
            <div>
              <img src={MacBook} alt="Rocket" className="md:max-w-screen" />
            </div>
            <div className="font-medium text-2xl">Product Catalog</div>
          </div>
        </SwiperSlide> */}
        <SwiperSlide className='mt-20 mb-20'>
          <div className="grid lg:grid-cols-2 grid-cols-1">
            <div>
              <img src={MacBook} alt="Rocket" className="md:max-w-screen" />
            </div>
            <div>
              <div className="font-medium text-2xl">Accurate Stock Verification</div>
              <div className='m-2'>
                <table>
                  <tr>
                    <td className='text-left text-md p-3 text-blue-800'><span className='mr-2'><TaskAltIcon/></span></td>
                    <td className='text-left text-md p-3'><span>Accurate Stock Verification is crucial for maintaining inventory integrity and <b className='font-semibold'>preventing losses.</b> </span></td>
                  </tr>
                  <tr>
                    <td className='text-left text-md p-3 text-blue-800'><span className='mr-2'><TaskAltIcon/></span></td>
                    <td className='text-left text-md p-3'><span>Our module simplifies the stock-taking process by providing tools to conduct regular audits and reconcile discrepancies. </span></td>
                  </tr>
                  <tr>
                    <td className='text-left text-md p-3 text-blue-800'><span className='mr-2'><TaskAltIcon/></span></td>
                    <td className='text-left text-md p-3'><span>The system supports both manual and automated stock counts, ensuring flexibility and accuracy. With real-time updates, you can identify and address stock issues promptly.</span></td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className='mt-20 mb-20'>
          <div className="grid lg:grid-cols-2 grid-cols-1">
            <div>
              <img src={MacBook} alt="Rocket" className="md:max-w-screen" />
            </div>
            <div>
              <div className="font-medium text-2xl">Comprehensive Reporting</div>
              <div className='m-2'>
                <table>
                  <tr>
                    <td className='text-left text-md p-3 text-blue-800'><span className='mr-2'><TaskAltIcon/></span></td>
                    <td className='text-left text-md p-3'><span>Our Comprehensive Reporting module provides powerful tools to analyze and interpret your business data</span></td>
                  </tr>
                  <tr>
                    <td className='text-left text-md p-3 text-blue-800'><span className='mr-2'><TaskAltIcon/></span></td>
                    <td className='text-left text-md p-3'><span>This module offers a wide range of reports covering <b className='font-semibold'>Sales, Inventory, Purchases, Customer behavior, and Financial performance</b></span></td>
                  </tr>
                  <tr>
                    <td className='text-left text-md p-3 text-blue-800'><span className='mr-2'><TaskAltIcon/></span></td>
                    <td className='text-left text-md p-3'><span>By accessing these detailed reports, you can gain valuable insights into various aspects of your business and make data-driven decisions.</span></td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </SwiperSlide>
        </Swiper>
    </div>
  );
};

export default SliderComponent;
