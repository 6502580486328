import ChooseUs from "./ChooseUs";
import Features from "./Features";
import WatchDemo from "./WatchDemo";

export default function BodyContent(){
    return(
        <div>
            <ChooseUs/>
            <Features/>
            <WatchDemo/>
        </div>
    );
};