import MacBook from '../assets/macbook.png';
import * as React from 'react';
import PlayCircleFilledOutlinedIcon from '@mui/icons-material/PlayCircleFilledOutlined';
import { useSpring ,animated } from '@react-spring/web'
export default function Headers(){
    const springs = useSpring({
        from: { y: 200 },
        to: { y: 0 },
        config: {tension: 300, friction: 10, duration: 1000 },
      })

    
    
    return(
        <animated.div style={{ ...springs,
            transform: springs.y.to(y => `translatey(${y}px)`), }} >
        <div className="header text-white px-10 lg:px-60 md:px-40 sm:px-20 h-screen flex" id="home">
            <div class="grid lg:grid-cols-2 grid-cols-1 items-center">
           
   
                <div>
                    <h1 className="md:text-6xl md:leading-snug md:mt-0 text-4xl font-medium leading-snug mt-10">
                        Streamline Jewellery<br/>business with <b><span className="brand">BillingZone</span></b>
                    </h1>
                    <p className="mt-4 font-medium">
                        Elevate your Jewellery business with our streamlined billing solution, designed for precision and efficiency!
                    </p>
                    <a href="#watchDemo">
                        <div className='demo flex items-center gap-5 mt-4'>
                            <span className='icon shadow-md rounded-full border pointer'><PlayCircleFilledOutlinedIcon style={{height:"60px",width:"60px"}}/></span>
                            <span className='hover:underline'>
                                Watch Demo
                            </span>
                        </div>
                    </a>
                </div>
                <div>
                    <img src={MacBook} alt="Desktop" className="md:max-w-screen-l"></img>
                </div>
                
                
               
   
                

                
            </div>
        </div>
        </animated.div >
    );
};