// import logo from './logo.svg';
import './App.css';
import BodyContent from './Components/BodyContent';
import Headers from './Components/Header';
import NavBar from './Components/NavBar';
import ContactUs from './Components/contactus';

function App() {
  return (
    <div className="bg-gradient-to-r from-blue-900 to-blue-500">
       <NavBar/>
       <Headers/>
       <BodyContent/>
       <ContactUs/>
    </div>
  );
}

export default App;
