
import SliderComponent from './SliderComponent';
export default function Features(){
    return(
        <div className="bg-blue-50 block px-10 lg:px-60 md:px-40 sm:px-20 pt-20" id='features'>
            <div className="text-center flex flex-col justify-center items-center">
                <h1 className="text-4xl font-semibold">Your All-In-One Management System</h1>
                <SliderComponent/>
            </div>
        </div>
    );
}